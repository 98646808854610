import React from 'react'

import SEO from 'components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />

    <main className="overflow-hidden flex-grow text-center p-10 lg:p-20">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </main>
  </>
)

export default NotFoundPage
